<template>
  <div class="navbar-holder shadow-sm">
    <b-navbar class="container" toggleable="lg">
      <b-navbar-brand to="/">
        <img src="@/assets/logo.svg" class="d-inline-block align-top" style="height: 40px;" alt="myTheben Portal">
      </b-navbar-brand>

      <b-navbar-toggle target="nav_dropdown_collapse"></b-navbar-toggle>

      <b-collapse is-nav id="nav_dropdown_collapse" class="text-right">
        <b-navbar-nav v-show="showSubMenu">
          <b-nav-item to="/account">{{ $t('views.partials.navBar.manageAccount') }}</b-nav-item>
          <b-nav-item v-if="gateways.length" to="/gateways">{{ $t('views.partials.navBar.manageGateways') }}</b-nav-item>
<!--          <b-nav-item v-if="gateways.length" to="/mysubscriptions">{{ $t('views.partials.navBar.manageMySubscriptions') }}</b-nav-item>-->
          <b-nav-item v-if="gateways.length" to="/users">{{ $t('views.partials.navBar.manageUsers') }}</b-nav-item>
<!--          <b-nav-item v-if="subscriptions.length" to="/subscriptions">{{ $t('views.partials.navBar.manageSubscriptions') }}</b-nav-item>-->

        </b-navbar-nav>

        <b-navbar-nav class="ml-auto">
          <b-nav-item @click="openRegisterModal()" v-if="!connectedUser">{{
              $t('views.partials.navBar.register')
            }}
          </b-nav-item>
          <b-nav-item @click="openLogInModal()" v-if="!connectedUser">{{
              $t('views.partials.navBar.logIn')
            }}
          </b-nav-item>
          <b-nav-item to="/account" v-if="connectedUser" exact>
            {{ $t('views.partials.navBar.account') }}
            <b>{{ connectedUser.firstName }} {{ connectedUser.lastName }}</b>
            <span class="role-text" v-show="userRole"> ({{ userRole }})</span>
          </b-nav-item>
          <b-nav-item disabled v-if="connectedUser && false">{{ $t('views.partials.navBar.contact') }}</b-nav-item>
          <b-nav-item v-if="connectedUser" @click="logOut()">{{ $t('views.partials.navBar.logOut') }}</b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
import EventBus from "@/views/partials/EventBus";
import Auth from '@/services/ACL';
import User from '@/models/User';
import router from "@/router";
import Gateway from "@/models/Gateway";
import Vue from "vue";
import Subscription from "@/models/Subscription";

export default {
  data: function () {
    return {
      connectedUser: null,
      activeMenu: null,
      gateways: [],
      subscriptions: []

    }
  },
  methods: {
    openLogInModal: () => EventBus.$emit('openLogInModal'),
    openRegisterModal: () => EventBus.$emit('openRegisterModal'),
    logOut: () => {
      EventBus.$emit('openLoader');
      new User().logOut();
      //EventBus.$emit('closeLoader');
    },
    refreshGatewaysList: function () {
      if (this.connectedUser) {
        EventBus.$emit('openLoader');
        return new Promise((resolve, reject) => {
          Gateway.getGatewaysUser().then(value => {

            this.gateways = value.data;

            EventBus.$emit('closeLoader');
            resolve();
          })
              .catch(() => reject(() => {
                EventBus.$emit('closeLoader');
                EventBus.$emit('openErrorModal')
              }));
        });
      }
    },
    refreshSubscriptionsList: function() {
      if (this.connectedUser) {
        EventBus.$emit('openLoader');
        return new Promise((resolve, reject) => {
          Subscription.getSubscriptionForUser().then(value => {
            this.subscriptions = value.data;

            for (var i = 0; i < this.subscriptions.length; i++) {
              let subscription = this.subscriptions[i];

              if (subscription.owner) {
                new User().getUserInfo(subscription.owner)
                    .then((response) => {
                      let fullName = `${response.data.firstName} ${response.data.lastName}`;
                      Vue.set(this.users, subscription.owner, fullName);
                    })
              }
            }
            EventBus.$emit('closeLoader');
            resolve();
          })
              .catch(() => reject(() => {
                EventBus.$emit('closeLoader');
                EventBus.$emit('openErrorModal')
              }));
        });
      }
    }
  },

  beforeCreate: async function () {
    await Auth.initialized;

  },
  created: function () {
    EventBus.$on('loggedIn', () => {
      this.connectedUser = Auth.connectedUser;
      this.refreshGatewaysList();
      // this.refreshSubscriptionsList();
    });

    EventBus.$on('loggedOut', () => {
      this.connectedUser = null;
      router.push('/login');
    })

    Auth.isConnected
        .then(value => {
          this.connectedUser = value;
          this.refreshGatewaysList();
          // this.refreshSubscriptionsList();
        })
        .catch(() => {
          this.connectedUser = false;
        });
  },
  computed: {
    showSubMenu: function () {
      return ['account', 'gateways', 'mysubscriptions', 'users'].indexOf(this.activeMenu) >= 0;
    },
    userRole: function () {
      return this.connectedUser.roles[0] === 'thebensupport' ? 'Theben Support' : null;
    }
  },
  watch: {
    $route() {
      this.activeMenu = this.$route.name;
    }
  }
};
</script>

<style scoped>
.navbar-holder {
  padding: 10px 0;
  margin-bottom: 20px;
}

.role-text {
  color: green;
}
</style>
