<template>
  <b-modal ref="errorModal" hide-footer hide-header-close :title="$t('views.modals.error.title')">
    <b-row>
      <b-col cols="12" class="text-center">
        <img src="@/assets/error.png" />
      </b-col>
      <b-col cols="12">
        <p>{{$t('views.modals.error.message')}}</p>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
  import EventBus from "@/views/partials/EventBus";
  
  export default {
    mounted: function() {
      EventBus.$on('openErrorModal', () => {
        this.$refs.errorModal.show();
  
        setTimeout(() => {
          this.$refs.errorModal.hide();
        }, 10000);
      });
    }
  }
</script>
