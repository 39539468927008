<template>
    <b-modal :no-close-on-backdrop="true" ref="selectCountryModal" centered hide-footer hide-header-close
             :title="$t('views.modals.selectCountry.title')">
        <b-form class="text-left" v-on:submit.prevent="saveCountry()">
            <b-alert :variant="formAlert.variant" :show="formAlert.show">
                <div>{{ formAlert.message }}</div>
            </b-alert>
            <p>{{ $t('views.modals.selectCountry.message') }}</p>
            <b-form-group :label="$t('common.models.user.country')" :state="!shallValidate || !userValidation.country"
                          :invalid-feedback="$t(userValidation.country, {name: $t('common.models.user.country')})">
                <template v-if="connectedUser">
                    <select v-model="country">
                        <option :value="null" disabled>{{ $t('common.select-default-country') }}</option>
                        <option v-for="country in countries" :value="country.value">
                            {{ country.label }}
                        </option>
                    </select>
                </template>
            </b-form-group>

            <b-row>
                <b-col>
                    <b-button type="submit" v-bind:disabled="pending" class="col-12"
                              variant="primary">
                        {{ $t('common.save') }}
                    </b-button>
                </b-col>
            </b-row>
        </b-form>
    </b-modal>
</template>

<script>
import EventBus from "@/views/partials/EventBus";
import Gateway from "@/models/Gateway";
import Auth from "@/services/ACL";
import User from "@/models/User";
import Vue from "vue";

const countries = require('i18n-iso-countries')
countries.registerLocale(require('i18n-iso-countries/langs/en.json'))

export default {
    data: () => {
        return {
            gatewayName: "",
            gateway: {
                installationName: null
            },
            formAlert: {
                show: false,
                variant: null,
                message: null
            },
            connectedUser: null,
            shallValidate: false,
            pending: false,
            country: "null"
        }
    },
    methods: {
        closeModal: function () {
            this.$refs.selectCountryModal.hide();
        },
        saveCountry: function () {

            this.shallValidate = true;
            if (Object.keys(this.userValidation).length === 0) {
                let user = new User(this.connectedUser);
                delete user.username;
                user.billingAddress.country = this.country;
                EventBus.$emit('openLoader');

                user.save(true)
                    .then(result => {

                        this.formAlert = {
                            show: true,
                            variant: "success",
                            message: this.$t('views.manageAccount.form.alert.success')
                        }
                        this.t = setTimeout(() => {
                            this.formAlert = {
                                show: false,
                                variant: null,
                                message: null
                            }
                            EventBus.$emit('closeLoader');

                            this.$refs.selectCountryModal.hide();
                        }, 2000);

                    })
                    .catch((error) => {

                        //es-lint-disable-next-line
                        console.log('connection error', error);

                        this.formAlert = {
                            show: true,
                            variant: "danger",
                            message: this.$t('views.manageAccount.form.alert.error')
                        }
                        EventBus.$emit('closeLoader');

                    });
            }
        }
    },
    computed: {
        locale() {
            return this.$i18n._vm.locale
        },
        userValidation() {
            let user = new User(this.connectedUser);
            let criteria = {
                firstName: {
                    required: true
                },
                lastName: {
                    required: true
                },
                email: {
                    required: true
                },
                country: {
                    required: true
                },
            };

            let validations = {};
            if (this.country.length == 0 ) validations.country = "common.validation.required";
            if (this.country == "null") validations.country = "common.validation.required";


            Object.assign(validations, user.validate(criteria));

            return validations;
        },
        countries() {
            const list = countries.getNames('en', {select: 'official'})
            return Object.keys(list).map((key) => ({value: key, label: list[key]}))
        }
    },
    beforeCreate: async function () {

    },
    mounted: function () {
        EventBus.$on('openSelectCountryModal', user => {
            this.connectedUser = user;
            setTimeout(() => {
                this.$refs.selectCountryModal.show();
            }, "100");
        });
    }
}
</script>
<style>
select {
    display: inline-block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 1.75rem 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    vertical-align: middle;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat;
}
</style>
