<template>

  <div id="app">
    <!-- PortalVersion - {{version}} -->

    <NavigationBar v-if="$route.meta.header != 'client'"></NavigationBar>
  
    <router-view class="main"  />

      <Footer v-if="$route.meta.header != 'client'"></Footer>
    <Loader />
  
    <LogInModal/>
    <SendResetPasswordLinkModal/>
    <RegisterModal/>
    <ErrorModal />
  </div>

</template>

<script>
  import "bootstrap-vue/dist/bootstrap-vue.css";
  import 'bootstrap-vue/dist/bootstrap-vue-icons.min.css'


import Vue from "vue";
  import EventBus from "@/views/partials/EventBus";
  import router from "@/router";
  import BootstrapVue from "bootstrap-vue";
  import { BootstrapVueIcons } from 'bootstrap-vue'
  import NavigationBar from "@/views/partials/NavigationBar";
  import Footer from "@/views/partials/Footer";
  import Loader from "@/views/partials/Loader";
  import LogInModal from "@/views/modals/LogIn";
  import SendResetPasswordLinkModal from "@/views/modals/SendResetPasswordLink";
  import RegisterModal from "@/views/modals/Register";
  import ErrorModal from "@/views/modals/Error";
  import LegalsModal from "@/views/modals/Legals";
  import CookieLaw from 'vue-cookie-law'
  import DatePicker from 'vue2-datepicker';
  import 'vue2-datepicker/index.css';


  import cfg from "@/services/config";
  import SelectCountryModal from "@/views/modals/SelectCountry.vue";
  
  Vue.use(BootstrapVue);
  Vue.use(BootstrapVueIcons)






  export default {
    components: {
      NavigationBar: NavigationBar,
      Footer: Footer,
      LogInModal: LogInModal,
      SendResetPasswordLinkModal: SendResetPasswordLinkModal,
      RegisterModal: RegisterModal,
      ErrorModal: ErrorModal,
      Loader: Loader,
      CookieLaw: CookieLaw
    },
    mounted () {
      EventBus.$on('loggedOut', () => {
        router.go('/login');
      });


      var cookieFirstConsent = JSON.parse(localStorage.getItem('cookiefirst-consent'));
      if(cookieFirstConsent !== null && cookieFirstConsent.necessary){
        document.getElementById("recaptchaLoader").src = "https://www.google.com/recaptcha/api.js?render=6Lc1CpQUAAAAALKEDl14TQGc9Fzrtu7e2RiXJow3";
      }
      window.addEventListener("cf_consent", function(event) {
        var cookieFirstConsent = event.detail;
        if(cookieFirstConsent !== null && cookieFirstConsent.necessary){
          document.getElementById("recaptchaLoader").src = "https://www.google.com/recaptcha/api.js?render=6Lc1CpQUAAAAALKEDl14TQGc9Fzrtu7e2RiXJow3";
        }
      });

    },
    computed: {
      recaptcha: () => cfg.recaptcha,
      version: () => cfg.portalVersion,

    }
  };
</script>

<style lang="scss">
  /* Bootstrap 4 theming. */
  
  // variables
  $theben-blue: #144a81;
  $theben-gray: #f2f2f2;
  $theben-gray-dark: #979696;
  // colors
  $primary: $theben-blue;
  $secondary: $theben-gray;
  // cards
  $card-bg: $secondary;
  $card-border-color: transparent;
  @import "../node_modules/bootstrap/scss/bootstrap.scss";
  
  /* Global theming. */
  
  @font-face {
    font-family: "Blogger Sans";
    src: url("assets/fonts/BloggerSans.eot");
    src: url("assets/fonts/BloggerSans.eot?#iefix") format("embedded-opentype"), url("assets/fonts/BloggerSans.woff2") format("woff2"), url("assets/fonts/BloggerSans.woff") format("woff"), url("assets/fonts/BloggerSans.svg#BloggerSans") format("svg");
    font-weight: normal;
    font-style: normal;
  }
  
  #nav {
    padding: 30px;
  }
  * {
    font-family: "Blogger Sans", Helvetica, Arial, sans-serif;
  }
  
  #app {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    font-family: "Blogger Sans", Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .main {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: 60vh;
    padding-bottom: 30px;
  }
  
  .btn.btn-secondary {
    color: $primary;
  }
  
  input.form-control {
    color: $primary;
  }
  
  fieldset>legend {
    color: $theben-gray-dark;
  }
  
  a.disabled {
    pointer-events: none;
  }

  /* Google reCaptcha */
  .grecaptcha-badge { visibility: hidden; }

  /* Cookies Vue */


   .Cookie__buttons .dark-theben {
    background: $primary;
    border-radius: 0.25rem;
     padding: .625em 3.125em;
     color: #fff;
     border: 0;
     font-size: 1em;
      &:hover {
        background: scale-color($primary, $lightness: -30%);
     }
  }
  .Cookie__content {
    flex: 1;
  }

  @media (min-width: 1400px){
   .container, .container-sm, .container-md, .container-lg, .container-xl {
     max-width: 1400px;
   }
  }


</style>
