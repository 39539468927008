<template>
   <b-modal size="lg" ref="legalModal" centered hide-footer hide-header-close :title="title">
      <div class="embed-responsive embed-responsive-16by9 z-depth-1-half">
         <iframe id="iframe" class="embed-responsive-item" :src="tlink" @load="load"></iframe>
      </div>
   </b-modal>

</template>

<script>
   import EventBus from "@/views/partials/EventBus";
   var scope = {
      tmpl: "",
      title: ""
   };
   export default {
      data: () => {
         return scope
      },
      methods: {
         load: function() {
            //let frame = document.getElementById('iframe').contentDocument;
         }

      },
      computed: {
         tlink: function() {
            return '/html/'+scope.tmpl;
         }
      },
      mounted: function()  {
         EventBus.$on('openLegalModal', (title, tmpl) => {
            scope.title = title;
            scope.tmpl = tmpl;
            this.$refs.legalModal.show();
            this.pending = false;
         });
      }
   };
</script>
