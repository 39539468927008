import Vue from 'vue';
import VueI18n from 'vue-i18n';
import en from '@/services/translations/en.json'
import de from '@/services/translations/de.json'
import fr from '@/services/translations/fr.json'
import es from '@/services/translations/es.json'
import it from '@/services/translations/it.json'
import nl from '@/services/translations/nl.json'


Vue.use(VueI18n);

export const i18n = new VueI18n({
    locale: localStorage.getItem('lang') || (navigator.language || navigator.userLanguage).split('-').shift(),
    fallbackLocale: 'de',
    messages: {
        en,
        de,
        fr,
        es,
        nl,
        it,
    },
});
