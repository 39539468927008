<template>
    <b-modal id="expirationModal" ref="expirationReminderModal" centered hide-footer hide-header-close
             :title="$t('views.modals.expirationReminder.title')">

      <!--    <div v-for="(expired, index) in expirationData.expired">-->
      <!--      <p>{{ expired.serial }}</p>-->
      <!--    </div>-->
      <!--    <div v-for="(expireSoon, index) in expirationData.expireSoon">-->
      <!--      <p>{{ expireSoon.serial }}</p>-->
      <!--    </div>-->
      <b-row>
        <div class="table-responsive  col-md-12 col-sm-12">
          <table v-if="lengthOfAllDevices >= 0 " id="expireSoon" class="table text-left sortable">
            <thead class="bg-primary text-white">
            <tr class="expiration">
              <th>{{ $t('views.manageGateways.list.columns.installationName') }}
              </th>
              <th>{{ $t('views.modals.expirationReminder.serial') }}
              </th>
              <th>{{ $t('views.modals.expirationReminder.expireSoon') }}
              </th>
              <th>{{ $t('views.modals.expirationReminder.action') }}
              </th>
            </tr>
            </thead>
            <tbody>
            <tr class="expiration" v-for="(expireSoon, index) in expirationData.listOfAllDevicesToShow">
              <td>{{ expireSoon.name }}</td>
              <td>{{ expireSoon.serial }}</td>
              <td>{{ formatDate(expireSoon.expiration, $t('common.dates.justDate')) }}</td>
              <td class="text-center">
                  <b-button target="_blank" href="https://services.theben.de/luxorliving-fernzugriff/DU970000">
                    {{ $t('views.modals.expirationReminder.renew') }}
                  </b-button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
<!--        <div class="table-responsive modal-table col-md-6 col-sm-12">-->
<!--          <table v-if="lengthExpired >= 0" id="expired" class="table text-left sortable">-->
<!--            <thead class="bg-primary text-white">-->
<!--            <tr>-->
<!--              <th>{{ $t('views.modals.expirationReminder.serial') }}-->
<!--              </th>-->
<!--              <th>{{ $t('views.modals.expirationReminder.expired') }}-->
<!--              </th>-->
<!--            </tr>-->
<!--            </thead>-->
<!--            <tbody>-->
<!--            <tr v-for="(expired, index) in expirationData.expired">-->
<!--              <td>{{ expired.serial }}</td>-->
<!--              <td>{{ expired.expiration }}</td>-->
<!--            </tr>-->
<!--            </tbody>-->
<!--          </table>-->
<!--        </div>-->
      </b-row>
      <b-row>
        <b-col>
          <b-button class="col-6" v-bind:disabled="pending" variant="secondary" @click="closeModal()">
            {{ $t('common.ok') }}
          </b-button>
        </b-col>
      </b-row>

    </b-modal>
</template>

<script>
import EventBus from "@/views/partials/EventBus";
import User from "@/models/User";
import router from "@/router";
import moment from "moment";

export default {
  data: () => {
    return {
      formAlert: {
        show: false,
        variant: null,
        message: null
      },
      pending: false,
      expirationData: "",
      lengthExpireSoon: 0,
      lengthExpired: 0,
      lengthFree : 0,
      lengthCancelled : 0,
      lengthOfAllDevices: 0
    }
  },
  methods: {
    closeModal: function () {
      this.$refs.expirationReminderModal.hide();
      EventBus.$emit("closeExpirationReminderModal");
    },
    formatDate: function (value, format) {
      let d = new Date(value);
      return value ? moment(d).format(format) : " -?- ";
    }
  },
  mounted: function () {
    EventBus.$on('openExpirationReminderModal', payload => {
      this.pending = false;
      this.expirationData = payload;
      // this.lengthExpired = this.expirationData.expired.length;
      // this.lengthExpireSoon = this.expirationData.expireSoon.length;
      this.lengthFree = this.expirationData.free.length;
      this.lengthCancelled = this.expirationData.cancelled.length;
      this.lengthOfAllDevices = this.expirationData.listOfAllDevicesToShow.length;
      this.$refs.expirationReminderModal.show()
    });
  }
}


</script>

<style>

#expirationModal .modal-md {
  max-width: 700px !important;
}

#expirationModal .table th, #expirationModal .table td {
  padding: 0.75rem;
  vertical-align: middle!important;
  border-top: 1px solid #dee2e6;
}

#expirationModal .text-center {
  text-align: left;
}


</style>
