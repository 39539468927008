<template>
  <b-modal size="lg" id="registerModal" ref="registerModal" centered hide-footer hide-header-close
           :title="$t('views.modals.register.title')">
    <b-form v-on:submit.prevent="register()" class="text-left" novalidate>
      <b-alert :variant="formAlert.variant" :show="formAlert.show">
        <div>{{ formAlert.message }}</div>
      </b-alert>
      <h3 class="header">{{ $t('views.modals.register.offer') }}</h3>
      <b-row>
        <b-col cols="5">
          <p class="header">{{ $t('views.modals.register.advantages_1') }}</p>
        </b-col>
        <b-col>
          <p class="header">{{ $t('views.modals.register.advantages_2') }}</p>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="5">
          <p class="header">{{ $t('views.modals.register.advantages_3') }}</p>
        </b-col>
        <b-col>
          <p class="header">{{ $t('views.modals.register.advantages_4') }}</p>
        </b-col>
      </b-row>

      <p class="header last">{{ $t('views.modals.register.advantages_5') }}</p>

      <b-row>
        <b-col cols="6">

          <b-form-group :state="!shallValidate || !userValidation.firstName"
                        :invalid-feedback="$t(userValidation.firstName, {name: $t('common.models.user.firstName')})">
            <b-form-input type="text" :placeholder="$t('common.models.user.firstName')"
                          v-model="firstName"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group :state="!shallValidate || !userValidation.lastName"
                        :invalid-feedback="$t(userValidation.lastName, {name: $t('common.models.user.lastName')})">
            <b-form-input type="text" :placeholder="$t('common.models.user.lastName')"
                          v-model="lastName"></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">

          <b-form-group :state="!shallValidate || !userValidation.email"
                        :invalid-feedback="$t(userValidation.email, {name: $t('common.models.user.email')})">
            <b-form-input type="email" :placeholder="$t('common.models.user.email')" v-model="email"></b-form-input>
          </b-form-group>
        </b-col>

      </b-row>
      <!--        </b-col>-->
      <!--        <b-col>-->
      <!--      <b-form-group :state="!shallValidate || !userValidation.c_email"-->
      <!--                    :invalid-feedback="$t(userValidation.c_email, {name: $t('common.models.user.emailConfirmation'), confirmationOf: $t('common.models.user.email')})">-->
      <!--        <b-form-input type="email" :placeholder="$t('common.models.user.emailConfirmation')"-->
      <!--                      v-model="c_email"></b-form-input>-->
      <!--      </b-form-group>-->

      <b-row>
        <b-col cols="6">

          <b-form-group :state="!shallValidate || !userValidation.password"
                        :invalid-feedback="$t(userValidation.password, {name: $t('common.models.user.password')})">
            <b-form-input type="password" required :placeholder="$t('common.models.user.password')"
                          v-model="password"></b-form-input>
            <!--        <b-form-text>{{ $t('common.helpers.user.password') }}</b-form-text>-->
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group :state="!shallValidate || !userValidation.c_password"
                        :invalid-feedback="$t(userValidation.c_password, {name: $t('common.models.user.passwordConfirmation'), confirmationOf: $t('common.models.user.password')})">
            <b-form-input type="password" required :placeholder="$t('common.models.user.passwordConfirmation')"
                          v-model="c_password"></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-form-text>{{ $t('common.helpers.user.password') }}</b-form-text>

      <br>

      <!--      <h3 class="header data">Ihre Adresse</h3>-->
      <!--      <b-row>-->
      <!--        <b-col cols="8">-->
      <!--          <b-form-group :state="!shallValidate || !userValidation.street"-->
      <!--                        :invalid-feedback="$t(userValidation.lastName, {name: $t('common.models.user.street')})">-->
      <!--            <b-form-input type="text" :placeholder="$t('common.models.user.street')" v-model="street"></b-form-input>-->
      <!--          </b-form-group>-->
      <!--        </b-col>-->
      <!--        <b-col>-->
      <!--          <b-form-group :state="!shallValidate || !userValidation.number"-->
      <!--                        :invalid-feedback="$t(userValidation.number, {name: $t('common.models.user.number')})">-->
      <!--            <b-form-input type="text" :placeholder="$t('common.models.user.number')" v-model="number"></b-form-input>-->
      <!--          </b-form-group>-->
      <!--        </b-col>-->
      <!--      </b-row>-->

      <!--      <b-row>-->
      <!--        <b-col cols="3">-->
      <!--          <b-form-group :state="!shallValidate || !userValidation.zipcode"-->
      <!--                        :invalid-feedback="$t(userValidation.zipcode, {name: $t('common.models.user.zipcode')})">-->
      <!--            <b-form-input type="text" :placeholder="$t('common.models.user.zipcode')" v-model="zipcode"></b-form-input>-->
      <!--          </b-form-group>-->
      <!--        </b-col>-->
      <!--        <b-col>-->
      <!--          <b-form-group :state="!shallValidate || !userValidation.city"-->
      <!--                        :invalid-feedback="$t(userValidation.city, {name: $t('common.models.user.city')})">-->
      <!--            <b-form-input type="text" :placeholder="$t('common.models.user.city')" v-model="city"></b-form-input>-->
      <!--          </b-form-group>-->
      <!--        </b-col>-->
      <!--      </b-row>-->
      <!--      <b-form-group :state="!shallValidate || !userValidation.country"-->
      <!--                    :invalid-feedback="$t(userValidation.country, {name: $t('common.models.user.country')})">-->
      <!--        <b-form-input type="text" :placeholder="$t('common.models.user.country')" v-model="country"></b-form-input>-->
      <!--      </b-form-group>-->


      <!--      <h3 class="header data">Firma</h3>-->
      <!--      <b-row>-->
      <!--        <b-col cols="4">-->
      <!--      <b-form-group :state="!shallValidate || !userValidation.company"-->
      <!--                    :invalid-feedback="$t(userValidation.company, {name: $t('common.models.user.company')})">-->
      <!--        <b-form-input type="text" :placeholder="$t('common.models.user.company')" v-model="company"></b-form-input>-->
      <!--      </b-form-group>-->
      <!--        </b-col>-->
      <!--        <b-col>-->
      <!--      <b-form-group :state="!shallValidate || !userValidation.department"-->
      <!--                    :invalid-feedback="$t(userValidation.department, {name: $t('common.models.user.department')})">-->
      <!--        <b-form-input type="text" :placeholder="$t('common.models.user.department')" v-model="department"></b-form-input>-->
      <!--      </b-form-group>-->
      <!--        </b-col>-->
      <!--        <b-col>-->
      <!--      <b-form-group :state="!shallValidate || !userValidation.ustId"-->
      <!--                    :invalid-feedback="$t(userValidation.ustId, {name: $t('common.models.user.ustId')})">-->
      <!--        <b-form-input type="text" :placeholder="$t('common.models.user.ustId')" v-model="ustId"></b-form-input>-->
      <!--      </b-form-group>-->
      <!--        </b-col>-->
      <!--      </b-row>-->


      <!--      <br>-->

      <!--      <h3 class="header data">Sonstiges</h3>-->
      <b-row>
        <b-col cols="6">
          <b-form-group :state="!shallValidate || !userValidation.country"
                        :invalid-feedback="$t(userValidation.country, {name: $t('common.models.user.country')})">
            <template>
              <select  v-model="country">
                <option :value="null" disabled>{{ $t('common.select-default-country') }}</option>
                <option v-for="country in countries" :value="country.value">
                  {{ country.label }}
                </option>
              </select>
            </template>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">

          <b-form-group :state="!shallValidate || !userValidation.category"
                        :invalid-feedback="$t(userValidation.category, {name: $t('common.models.user.category')})">
            <b-form-select null required :placeholder="$t('common.select-default-typ')" v-model="userCategory">
              <option :value="null" disabled>{{ $t('common.select-default-typ') }}</option>
              <option value="owner">{{ $t('common.models.user.categories.owner') }}</option>
              <option value="installer">{{ $t('common.models.user.categories.installer') }}</option>
              <option value="tenant">{{ $t('common.models.user.categories.tenant') }}</option>
              <option value="landlord">{{ $t('common.models.user.categories.landlord') }}</option>
              <option value="house-manager">{{ $t('common.models.user.categories.house_manager') }}</option>
              <option value="electro-planner">{{ $t('common.models.user.categories.electro_planner') }}</option>
              <option value="smart-metering">{{ $t('common.models.user.categories.smart_metering') }}</option>

            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group :state="!shallValidate || !userValidation.locale"
                        :invalid-feedback="$t(userValidation.locale, {name: $t('common.models.user.locale')})">
            <b-form-select required :placeholder="$t('common.models.user.locale')" v-model="userLocale">
              <option value="" disabled>{{ $t('common.select-default-language') }}</option>
              <option value="en">{{ $t('common.localizations.en') }}</option>
              <option value="de">{{ $t('common.localizations.de') }}</option>
              <option value="fr">{{ $t('common.localizations.fr') }}</option>
              <option value="es">{{ $t('common.localizations.es') }}</option>
              <option value="it">{{ $t('common.localizations.it') }}</option>
              <option value="nl">{{ $t('common.localizations.nl') }}</option>
            </b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-form-group :state="!shallValidate || !userValidation.cgu"
                    :invalid-feedback="$t(userValidation.cgu, {name: $t('views.modals.register.cguValidation')})">
        <b-form-checkbox v-model="cgu" value="true" unchecked-value="false" required>
          {{ $t('views.modals.register.cgu') }}
          <a :href="termsLink" target="_blank">{{ $t('views.modals.register.cguAGB') }}</a> & <a :href="privacyLink"
                                                                                                 target="_blank">{{
            $t('views.modals.register.cguData')
          }}</a>
        </b-form-checkbox>
      </b-form-group>
      <b-form-group>
        <b-button type="submit" v-bind:disabled="pending" variant="primary" class="w-100">
          {{ $t('views.modals.register.form.actions.register') }}
        </b-button>
      </b-form-group>
    </b-form>
    <p class="recaptcha-notice">
      <img src="https://www.gstatic.com/recaptcha/api2/logo_48.png">
      {{ $t('common.helpers.google.recaptcha') }}
    </p>
  </b-modal>

</template>

<script>
import User from "@/models/User";
import EventBus from "@/views/partials/EventBus";
import cfg from "@/services/config";

var scope = {
  firstName: "",
  lastName: "",
  email: "",
  c_email: "",
  password: "",
  c_password: "",
  cgu: "false",
  userLocale: "",
  userCategory: null,
  number: "",
  street: "",
  zipcode: "",
  city: "",
  country: "null",
  msg: "",
  formAlert: {
    show: false,
    variant: null,
    message: null
  },
  shallValidate: false,
  recaptchaKey: cfg.recaptcha,
  pending: false
};

const countries = require('i18n-iso-countries')
countries.registerLocale(require('i18n-iso-countries/langs/en.json'))


export default {
  data: () => {
    return scope;
  },
  methods: {
    register() {

      this.shallValidate = true

      let user = new User({
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        password: this.password,
        locale: this.userLocale,
        category: this.userCategory,
        billingAddress: {
          street: this.street,
          number: this.number,
          zipcode: this.zipcode,
          city: this.city,
          country: this.country,
        }

      });

      if (Object.keys(this.userValidation).length === 0) {
        this.pending = true;
        //grecaptcha.ready(() => {
        //console.log('recapt', cfg, cfg.recaptcha, '6Lc1CpQUAAAAALKEDl14TQGc9Fzrtu7e2RiXJow3');
        let cnt = 0;
        grecaptcha.execute(cfg.recaptcha, {action: 'subscribe'}).then((gtoken) => {
          user.captcha = gtoken;
          if (!gtoken) return;
          user.save(true).then(() => {
            this.formAlert = {
              show: true,
              variant: "success",
              message: this.$t('views.modals.register.form.alert.success')
            }

            setTimeout(() => {
              this.formAlert.show = false;
              this.$refs.registerModal.hide();
              EventBus.$emit('registered');
            }, 3000);
          })
              .catch((error) => {
                this.formAlert = {
                  show: true,
                  variant: "danger"
                }
                this.pending = false;
                switch (error.response.status) {
                  case 409:
                    this.formAlert.message = this.$t('views.modals.register.form.alert.conflict')
                    break
                  default:
                    this.formAlert.message = this.$t('common.requestStatuses.error')
                }
              });

        });
        // });


      }
    }
  },
  computed: {
    locale() {
      return this.$i18n._vm.locale
    },
    termsLink() {
      return "./html/terms-" + this.$i18n._vm.locale + ".html";
    },
    privacyLink() {
      return "./html/privacy-" + this.$i18n._vm.locale + ".html";
    },
    countries () {
      const list = countries.getNames('en', { select: 'official' })
      return Object.keys(list).map((key) => ({ value: key, label: list[key] }))
    },

    userValidation() {
      let user = new User({
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        password: this.password,
        locale: this.userLocale,
        category: this.userCategory,
        billingAddress: {
          street: this.street,
          number: this.number,
          zipcode: this.zipcode,
          city: this.city,
          country: this.country,
        }

      });
      let criteria = {
        firstName: {
          required: true
        },
        lastName: {
          required: true
        },
        email: {
          required: true
        },
        password: {
          required: true
        },
        locale: {
          required: true
        },
        category: {
          required: true
        },
        country: {
          required: true
        }

      };
      let validations = {};
      // if (this.email !== this.c_email) validations.c_email = "common.validation.mismatch";
      if (this.password !== this.c_password) validations.c_password = "common.validation.mismatch";
      if (this.cgu !== "true") validations.cgu = "common.validation.required";
      if (this.country.length == 0 ) validations.country = "common.validation.required";
      if (this.country == "null") validations.country = "common.validation.required";


      Object.assign(validations, user.validate(criteria));

      return validations;
    }
  },
  mounted: function () {
    EventBus.$on('openRegisterModal', () => {
      this.$refs.registerModal.show();
      let pendingInvitation = JSON.parse(localStorage.getItem('pendingInvitation'));
      if (pendingInvitation) {
        this.email = pendingInvitation.email || '';
      }

      this.pending = false;
    });

    if (this.locale) {
      this.userLocale = this.locale;
    }

    // if(!this.country){
    //   this.country = "DE";
    // }

    if (this.category) {
      this.userCategory = this.category;
    }

  }
};
</script>
<style lang="scss" scoped>
.recaptcha-notice {
  font-size: 0.8em;
  color: rgba(0, 0, 0, 0.45);
  text-align: left;

  img {
    float: left;
    height: 25px;
    margin: 0 7px;
  }

}

.header {
  color: rgba(0, 0, 0, 0.55);
}

h3.header {
  font-size: 1em;
  font-weight: bolder;
  word-spacing: -1px;
  letter-spacing: -0.1pt;

}

p.header {
  font-size: 1em;
  margin-bottom: 0em;
}

p.header.last {
  font-size: 1em;
  margin-bottom: 1em;
}

h3.header.data {
  font-size: 1em;
  font-size: inherit;
  font-weight: normal;
}

select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat;
}


</style>
