<template>
    <div class="backdrop" v-if="show">
        <div><img height="100px" width="100px" src="@/assets/clock.svg" alt="Loading"/></div>
    </div>
</template>

<script>
    import cfg from "@/services/config";
    import EventBus from "@/views/partials/EventBus";

    export default {
        data: () => {return {
            show: false
        };},
        mounted: function() {
            EventBus.$on('openLoader', () => {
                this.show = true;
            });
            EventBus.$on('closeLoader', () => {
                this.show = false;
            });
        }
    };
</script>

<style lang="scss" scoped>
    .backdrop {
        display: flex;
        position: fixed;
        height: 100vh;
        width: 100vw;
        background: rgba(0,0,0,0.33);
        justify-content: center;
        align-items: center;
        z-index: 9000;

    }
</style>

