'use strict';

import Vue from 'vue';
import Router from 'vue-router';
import Home from '@/views/Home';
import Loader from "@/views/partials/Loader";
import Analytics from "vue-analytics";
import cfg from "@/services/config";


Vue.use(Router);

let mainRouter = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            name: 'home',
            component: Home
        },
        {
            path: '/confirm-email/:id',
            name: 'confirmEmail',
            component: () => import('@/views/ConfirmEmail'),
            props: true
        },
        {
            path: '/confirm-password/:id',
            name: 'confirm-password',
            component: () => import('@/views/ConfirmPassword'),
            loading: Loader,
            delay: 10,
            props: true
        },
        {
            path: '/register-mobile',
            name: 'register-mobile',
            component: () => import('@/views/Register'),
            loading: Loader,
            delay: 10,
            props: true
        },
        {
            path: '/register-mobile-finished',
            name: 'register-mobile',
            component: () => import('@/views/Register'),
            loading: Loader,
            delay: 10,
            props: true
        },
        {
            path: '/confirm-handover/:id/:code',
            name: 'confirm-handover',
            component: () => import('@/views/ConfirmHandover'),
            loading: Loader,
            delay: 10,
            props: true
        },
        {
            path: '/confirm-invite/:id/:code',
            name: 'confirm-invite',
            component: () => import('@/views/ConfirmInvitation'),
            loading: Loader,
            delay: 10,
            props: true
        },
        {
            path: '/login',
            name: 'logIn',
            component: () => import('@/views/LogIn'),
            loading: Loader,
            delay: 10
        },
        {
            path: '/account',
            name: 'account',
            component: () => import('@/views/ManageAccount'),
            loading: Loader,
            delay: 10
        },
        {
            path: '/gateways',
            name: 'gateways',
            component: () => import('@/views/ManageGateways'),
            loading: Loader,
            delay: 10
        },
        {
            path: '/mysubscriptions',
            name: 'mysubscriptions',
            component: () => import('@/views/ManageMySubscriptions'),
            loading: Loader,
            delay: 10
        },
        {
            path: '/users',
            name: 'users',
            component: () => import('@/views/ManageUsers'),
            loading: Loader,
            delay: 10
        },
        {
            path: '/subscriptions',
            name: 'subscriptions',
            component: () => import('@/views/ManageSubscriptions'),
            loading: Loader,
            delay: 10
        },
        {
            path: '/login-gateway',
            name: 'login-gateway',
            component: () => import('@/views/OAuthLoginVoiceController'),
            loading: Loader,
            delay: 10
        },
        {
            path: '/login-ifttt',
            name: 'login-ifttt',
            component: () => import('@/views/OAuthLoginIFTTT'),
            loading: Loader,
            delay: 10
        },
        {
            path: '/login-client',
            name: 'login-client',
            component: () => import('@/views/OAuthLoginClient'),
            meta: {
                header: "client"
            },
            loading: Loader,
            delay: 10
        },
        {
            path: '/login-client-alternative',
            name: 'login-client-alternative',
            component: () => import('@/views/OAuthLoginClientAlternative'),
            loading: Loader,
            delay: 10
        },
        {
            path: '*',
            component: () => import('@/views/404'),
            loading: Loader,
            delay: 10
        }
    ]
});
if (cfg.analytics) {
    Vue.use(Analytics, {
        id: cfg.analytics || "UA-146498077-1",
        router: mainRouter
    });
}

export default mainRouter;
