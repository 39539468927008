<template>
  <footer>


    <div class="newsletter mdc-theme--dark" style="background-color: #fff; padding-bottom: 45px; padding-top: 45px;">
      <div class="container">
        <div class="corner corner--blue">
          <div class="corner__body">
            <div class="newsletter__body">

              <div class="heading heading--1">Mehr Informationen gefällig?</div>
              <div class="heading heading--3 heading--bluelight">Abonnieren Sie unseren Newsletter!</div>


              <div class="newsletter__form mdc-theme--dark">

                <a class="button button--outline" href="https://www.theben.de/newsletter/">zum Newsletter anmelden</a>
              </div>


            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="service-bar">
      <div class="service-bar__container container">

        <div class="service-bar__item">
          <div class="service-bar__inner">

            <svg class="icon icon-company" viewBox="0 0 32 32">
              <use xlink:href="#company"></use>
            </svg>


            <div class="heading heading--4">Theben AG</div>


            <p>Hohenbergstraße 32<br> 72401 Haigerloch<br><br> Zentrale: <a href="[sitetree_link,id=]">+49 (0)74
              74/692-0</a><br>Vertriebsservice: <a class="phone-link" title="Vertriebssvervice"
                                                   href="tel:+49%20(0)74 74/ 692-533">+49 (0)74 74/ 692-533</a><br>
              E-Mail: <a href="mailto:info@theben.de?subject=Kontakt">info@theben.de</a></p>

          </div>
        </div>

        <div class="service-bar__item">
          <div class="service-bar__inner">

            <svg class="icon icon-headphone" viewBox="0 0 32 32">
              <use xlink:href="#headphone"></use>
            </svg>


            <div class="heading heading--4">Support</div>


            <p>Mo - Do: 7.00 bis 18.00 Uhr<br> Fr: 7.00 bis 16.00 Uhr<br><br> Telefon: <a class="phone-link"
                                                                                          href="tel:+49%20(0) 7474/692-369">+49
              (0) 7474/692-369</a><br> Fax: +49 (0) 7474/692-207<br>E-Mail: <a href="mailto:hotline@theben.de">hotline@theben.de</a>
            </p>

          </div>
        </div>

        <div class="service-bar__item">
          <div class="service-bar__inner">

            <svg class="icon icon-service" viewBox="0 0 32 32">
              <use xlink:href="#service"></use>
            </svg>


            <div class="heading heading--4">Service</div>


            <p><a href="https://www.theben.de/de/downloads/">Downloads</a><br><a title="Katalogbestellung"
                                                                                 href="https://www.theben.de/service/katalogbestellung/">Katalogbestellung</a><br><a
                title=" Kataloge und Prospekte" href="http://www.theben.de/service/kataloge-und-prospekte/">Kataloge und
              Prospekte</a><br><a title="Seminare und Webinare" href="http://www.theben.de/service/fachseminare/">Seminare
              und Webinare</a><br><a title="Fachpresse" href="http://www.theben.de/unternehmen/aktuelles/fachpresse/">Fachpresse</a>
            </p>

          </div>
        </div>

        <div class="service-bar__item">
          <div class="service-bar__inner">

            <svg class="icon icon-company" viewBox="0 0 32 32">
              <use xlink:href="#company"></use>
            </svg>

            <div class="heading heading--4">FAQ</div>


            <p>Sie haben Fragen zu unseren Produkten?</p>
            <ul>
              <li><a class="anyUrl-cms" title="FAQs zu Zeitschaltuhren"
                     href="https://www.theben.de/kontakt/hotline-faqs/faqs-zu-zeitschaltuhren/">FAQs zu
                Zeitschaltuhren</a></li>
              <li><a class="anyUrl-cms" title="FAQs zu Uhrenthermostaten"
                     href="https://www.theben.de/kontakt/hotline-faqs/faqs-zu-uhrenthermostaten/">FAQs zu
                Uhrenthermostaten</a></li>
              <li><a class="anyUrl-cms"
                     title="FAQs zu Lichtsteuerung mit Präsenzmeldern, Dämmerungsschaltern und Treppenlicht-Zeitschaltern"
                     href="https://www.theben.de/kontakt/hotline-faqs/faqs-zu-lichtsteuerung/">FAQs zu
                Lichtsteuerung<br></a></li>
              <li><a class="anyUrl-cms" title="FAQs zu KNX"
                     href="https://www.theben.de/kontakt/hotline-faqs/faqs-zu-knx/">FAQs zu KNX</a></li>
              <li><a class="anyUrl-cms" title="FAQs zu LUXORliving"
                     href="https://www.theben.de/kontakt/hotline-faqs/faqs-zu-luxorliving/">FAQs zu LUXORliving</a></li>
              <li><a class="anyUrl-cms" title="FAQs zu LEDs"
                     href="https://www.theben.de/kontakt/hotline-faqs/faqs-zu-leds/">FAQs zu LEDs</a></li>
            </ul>

          </div>
        </div>

      </div>
    </div>

    <div class="social-bar">
      <div class="social-bar__text">
        Besuchen Sie uns auf:
      </div>
      <div>

        <a href="https://www.youtube.com/user/TheThebenAG" title="Youtube">
          <svg class="icon icon-youtube" viewBox="0 0 32 32">
            <use xlink:href="#youtube"></use>
          </svg>
        </a>

        <a href="https://www.instagram.com/theben_ag/" title="Instagram">
          <svg class="icon icon-instagram" viewBox="0 0 32 32">
            <use xlink:href="#instagram"></use>
          </svg>
        </a>

        <a href="https://twitter.com/Theben_AG" title="Twitter">
          <svg class="icon icon-twitter" viewBox="0 0 32 32">
            <use xlink:href="#twitter"></use>
          </svg>
        </a>

        <a href="https://www.facebook.com/ThebenAG/" title="Facebook">
          <svg class="icon icon-facebook" viewBox="0 0 32 32">
            <use xlink:href="#facebook"></use>
          </svg>
        </a>

        <a href="https://de.linkedin.com/company/theben-ag" title="linkedin">
          <svg class="icon icon-linkedin" viewBox="0 0 32 32">
            <use xlink:href="#linkedin"></use>
          </svg>
        </a>

        <a href="https://www.kununu.com/de/theben" title="kununu">
          <svg class="icon icon-kununu" viewBox="0 0 32 32">
            <use xlink:href="#kununu"></use>
          </svg>
        </a>

        <a href="https://www.xing.com/companies/thebenag" title="xing">
          <svg class="icon icon-xing" viewBox="0 0 32 32">
            <use xlink:href="#xing"></use>
          </svg>
        </a>

      </div>

    </div>

    <div class="container mx-auto">
      <b-navbar toggleable="md" class="mx-auto bordertop">

        <b-navbar-nav class="mx-auto">

          <b-nav-item @click="openAGB($t('views.partials.footer.terms'),'terms')">{{
              $t('views.partials.footer.terms')
            }}
          </b-nav-item>
          <b-nav-item @click="openLegals($t('views.partials.footer.privacy'),'privacy')">
            {{ $t('views.partials.footer.privacy') }}
          </b-nav-item>
          <b-nav-item @click="openLegals($t('views.partials.footer.legal'),'legal')">
            {{ $t('views.partials.footer.legal') }}
          </b-nav-item>
          <b-nav-item @click="openLegals($t('views.partials.footer.cancellation'),'cancellation')">
            {{ $t('views.partials.footer.cancellation') }}
          </b-nav-item>
          <b-nav-text disabled>
            {{ $t('views.partials.footer.language') }}
            <b-link @click="changeLocale('en')">EN</b-link>
            |
            <b-link @click="changeLocale('de')">DE</b-link>
            |
            <b-link @click="changeLocale('fr')">FR</b-link>
            |
            <b-link @click="changeLocale('it')">IT</b-link>
            |
            <b-link @click="changeLocale('es')">ES</b-link>
            |
            <b-link @click="changeLocale('nl')">NL</b-link>

          </b-nav-text>
        </b-navbar-nav>
      </b-navbar>
      <div class="mx-auto">
        <small class="text-muted">© 2019 myTheben Portal <a href="https://www.theben.de/" target="_blank">
          theben.de </a></small>
      </div>

      <p class="version"> ¤ {{ version }}</p>
      <LegalsModal/>
    </div>
  </footer>

</template>

<script>
import cfg from "@/services/config";
import EventBus from "@/views/partials/EventBus";
import LegalsModal from "@/views/modals/Legals";

export default {
  components: {
    LegalsModal: LegalsModal
  },
  methods: {
    changeLocale(_locale) {
      localStorage.setItem('lang', _locale);
      this.$i18n._vm.locale = _locale;
    },
    openLegals(title, tmpl) {
      EventBus.$emit('openLegalModal', title, tmpl + "-" + this.$i18n._vm.locale + ".html");
    },
    openAGB() {
      var pdf = "/docs/AGB_" + this.$i18n._vm.locale + ".pdf";
      window.open(pdf);
    }

  },
  computed: {
    version: () => cfg.portalVersion
  }
};
</script>

<style lang="scss" scoped>
footer {
  box-shadow: 0 -0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  position: relative;

  .version {
    position: absolute;
    right: 16px;
    color: rgba(0, 0, 0, 0.55);
    bottom: -10px;
    font-size: 0.6em;
  }

  background-color: #eff0f1;
  //background: linear-gradient(90deg, rgb(12, 59, 107) 0%, rgb(29, 86, 148) 80%);
  //padding: 45px 30px

}

footer svg {
  overflow: hidden;
  vertical-align: middle !important;
  margin-bottom: 7px;
}

footer a, footer small, footer li {
  //color: white!important;
  color: #00487b !important;
  //text-decoration: underline;
  cursor: pointer;
}

footer a {
  text-decoration: underline !important;
}

footer .heading {
  font-weight: normal;
  margin: 0 0 20px 0 !important;
}

footer p {
  display: block !important;;
  margin-block-start: 1em !important;;
  margin-block-end: 1em !important;;
  margin-inline-start: 0px !important;;
  margin-inline-end: 0px !important;;
  font-size: 16px !important;
}

.bordertop{
  border-top: 3px solid #c4c7c8;
}

.service-bar {
  color: #00487b;
  background-color: #8dc474;
  padding-top: 50px;
  padding-bottom: 20px;
}


.service-bar__container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}

@media (min-width: 768px) {
  .service-bar {
    padding-top: 60px;
    padding-bottom: 0;
  }
}

@media (min-width: 992px) {
  .service-bar {
    padding-top: 70px;
  }
}

footer .mx-auto {
  background-color: #eff0f1 !important;
  //border-top: 3px solid #c4c7c8;

}

.service-bar__item {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  padding: 0 30px 30px 0;
}

@media (min-width: 768px) {
  .service-bar__item {
    width: 50%;
    padding-bottom: 60px;
  }
}

@media (min-width: 992px) {
  .service-bar__item {
    width: 25%;
    padding-bottom: 70px;
  }
}

.service-bar__inner {
  border-left: 3px solid rgba(0, 72, 123, 0.5);
  padding-left: 30px;
  text-align: left;
}

.service-bar .icon {
  width: 50px;
  height: 50px;
}

.service-bar a {
  color: #00487b;
}

.social-bar {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column;
  flex-flow: column;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  padding: 45px 30px;
  background-color: #eff0f1;
}

@media (min-width: 576px) {
  .social-bar {
    -webkit-flex-flow: row;
    flex-flow: row;
  }
}

.social-bar__text {
  margin-right: 20px;
}

.social-bar .icon {
  width: 40px;
  height: 40px;
  margin: 0 5px;
  stroke: #0f90d0;
  fill: #0f90d0;
}

.heading--4 {
  line-height: 1.1;
  font-size: 18px;
}

@media (min-width: 768px) {
  .heading--4 {
    font-size: 20px;
  }
}

@media (min-width: 992px) {
  .heading--4 {
    font-size: 24px;
  }
}

@media (min-width: 1200px) {
  .heading--4 {
    font-size: 26px;
  }
}



.newsletter.mdc-theme--dark .heading--1 {
  color: #eff0f1;
}

.newsletter.mdc-theme--dark .heading--3 {
  color: #0f90d0;
}


@media (min-width: 768px) {
  .corner__body {
    padding: 30px 30px 0 30px;
  }
}

@media (min-width: 992px) {
  .corner__body {
    padding: 50px 50px 0 50px;
  }
}

.corner--blue .corner__body {
  background-color: #00487b;
}

.heading--1 {
  line-height: 1.1;
  font-size: 34px;
}

@media (min-width: 768px) {
  .heading--1 {
    font-size: 44px;
  }
}

@media (min-width: 992px) {
  .heading--1 {
    font-size: 50px;
  }
}

@media (min-width: 1200px) {
  .heading--1 {
    font-size: 56px;
  }
}
.heading--3 {
  font-size: 20px;
  line-height: 1.1;
}

@media (min-width: 768px) {
  .heading--3 {
    font-size: 26px;
  }
}

@media (min-width: 992px) {
  .heading--3 {
    font-size: 30px;
  }
}

@media (min-width: 1200px) {
  .heading--3 {
    font-size: 34px;
  }
}

.corner::after {
  position: absolute;
  content: '';
  width: 0;
  height: 0;
  right: 0;
  bottom: 0;
  border-style: solid;
  border-width: 50px 50px 0 0;
  border-color: #fff transparent transparent transparent;
}
.corner--blue::after {
  border-top-color: #00487b;
}

.corner {
  position: relative;
  padding-bottom: 50px;
}

.corner::before {
  position: absolute;
  content: '';
  height: 50px;
  left: 0;
  right: 50px;
  bottom: 0;
  background-color: #fff;
}

.corner--blue::before {
  background-color: #00487b;
}

.mdc-theme--dark .button--outline {
  color: #fff!important;
  border-color: #fff!important;
  background-color: transparent !important;
}

footer .button {
  color: #fff!important;
  background-color: #00487b!important;
  border-color: #00487b!important;
  display: inline-block;
  border: 1px solid #00487b!important;
  border-radius: 4px;
  text-decoration: none!important;
  font-family: "NettoWebPro", -apple-system, system-ui, sans-serif, swap!important;
  font-size: 1rem!important;
  line-height: 2!important;
  padding: 3px 20px!important;
  cursor: pointer!important;
  transition: -webkit-transform .6s ease-out;
  transition: transform .6s ease-out!important;
  transition: transform .6s ease-out, -webkit-transform .6s ease-out!important;
}


</style>

