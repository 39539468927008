import axios from "axios";
import config from "@/services/config";
import router from "@/router";
import EventBus from "@/views/partials/EventBus";

function AuthenticatedAxios(auth) {
    this.request = (options) => {
        //add auth header to request
        if (!options.headers) {
            options.headers = {
                "Authorization": auth.authToken
            };
        } else {
            options.headers.Authorization = auth.authToken;
        }

        let P = new Promise((resolve, reject) => {
            //perform the request
            axios.request(options)
                .then(resolve)
                .catch((error) => { 
                    if(typeof(error.response)==='undefined'){
                        return resolve(error);
                    }
                    if (401 === error.response.status) {
                        if (auth.refreshToken) {
                            //on auth error try to refresh the accesstoken
                            auth.renewUserTokenWithToken(auth.refreshToken)
                                .then(() => {
                                    //restart the request xith refreshed token
                                    options.headers.Authorization = auth.authToken;
                                    axios.request(options).then(resolve).catch(reject);
                                })
                                .catch(() => {
                                    //es-lint-disable-next-line
                                    console.log('Token refresh unsuccessful.',router.currentRoute.fullPath);
                                    if(router.currentRoute.name == "logIn") return;
                                    router.push({name: 'logIn', query: {redirect: router.currentRoute.fullPath}})
                                });
                        } else {
                            //es-lint-disable-next-line
                            console.log('No refresh token found.', router.currentRoute.fullPath);
                            if(router.currentRoute.name == "logIn") return;
                            router.push({name: 'logIn', query: {redirect: router.currentRoute.fullPath}})
                        }
                    } else {
                        reject(error);
                    }
                });
        });

        return P;
    }
}

//service for authentication
function Auth() {
    this.authToken = null;
    this.isAuthenticated = false;
    this.connectedUser = null;
    this.authFaild = "";

    //use access-token to get current user info
    this.connectUserWithToken = (id, token) => {
        return axios({
            url: config.servers.auth.baseUrl + "user/" + id,
            method: 'GET',
            headers: {
                "Authorization": token
            }
        })
            .then(result => {
                this.authToken = token;
                this.connectedUser = result.data;
                this.isAuthenticated = true;

                //localStorage.setItem("auth", {user: this.connectedUser, token: this.authToken, refresh: this.refreshToken});
            })
            .catch(error => {
                this.authToken = null;
                this.isAuthenticated = false;
                this.connectedUser = null;
                this.authFaild = error.message;
                localStorage.setItem("auth", "{}");
            })
    };

    //refresh access token with refresh token
    this.renewUserTokenWithToken = (token) => {
        return new Promise((resolve, reject) => {
            axios({
                url: config.servers.auth.baseUrl + "token/renew",
                method: 'GET',
                headers: {
                    "Authorization": token
                }
            })
                .then(result => {
                    this.refreshToken = token;
                    this.authToken = result.data['access-token'];
                    this.isAuthenticated = true;

                    //save auth info in localStorage for automatic reconnection on browser refresh
                    var savedAuth = JSON.parse(localStorage.getItem("auth"));
                    savedAuth.token = this.refreshToken;
                    localStorage.setItem("auth", JSON.stringify(savedAuth));

                    resolve(result);
                })
                .catch(error => {
                    this.authToken = null;
                    this.isAuthenticated = false;
                    this.connectedUser = null;
                    this.authFaild = error.message;
                    localStorage.setItem("auth", "{}");
                    reject(error);
                })
        });
    };



    //declare initialization promise
    this._defered = {};
    this.initialized = new Promise((resolve, reject) => {
        this._defered.resolve = resolve;
        this._defered.reject = reject;
    });

    //declare user connected promise
    this.watchConnection = {};
    this.isConnected = new Promise((resolve, reject) => {
        this.watchConnection.resolve = resolve;
        this.watchConnection.reject = reject;
    });

    //check localStorage and try to authenticate with saved auth info
    var savedAuth = {};
    try {
        savedAuth = JSON.parse(localStorage.getItem("auth"));
    } catch (e) {
        savedAuth = localStorage.getItem("auth");
    }
    if (savedAuth && savedAuth.user && savedAuth.token) {
        this.renewUserTokenWithToken(savedAuth.token).finally(() => {
            if (this.authToken) {
                this.connectUserWithToken(savedAuth.user._id, this.authToken).finally(() => {
                    this._defered.resolve(this.isAuthenticated);
                    this.watchConnection.resolve(this.connectedUser);
                });
            } else {
                this._defered.resolve(this.isAuthenticated);
            }
        });
    } else {
        localStorage.setItem("auth", "{}");
        this._defered.resolve(this.isAuthenticated);
    }

    this.authenticatedAxios = new AuthenticatedAxios(this);
}

export default new Auth();
