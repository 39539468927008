

var cfg;
try {
    cfg = JSON.parse(process.env.VUE_APP_CONFIG);
} catch(e) {
    console.info("Malformed config file");
    cfg = {}
}
export default cfg;
