<template>
  <b-container>
    <ExpirationReminder :payload="_data.expirationReminder"/>
    <SelectCountryModal/>
    <b-row class="justify-content-md-center" style="flex: 1" align-v="center">
      <b-col cols="12" md="auto">
        <router-link class="card theben-card" to="/account">
          <img src="@/assets/user.png">
          <span>{{ $t('views.home.manageAccount') }}</span>
        </router-link>
      </b-col>
      <b-col v-if="gateways.length" cols="12" md="auto">
        <router-link to="/gateways" _data.gateways class="card theben-card">
          <img src="@/assets/gateway.png">
          <span>{{ $t('views.home.manageGateways') }}</span>
        </router-link>
      </b-col>
      <b-col v-if="gateways.length && isLicensingCountry" cols="12" md="auto">
        <router-link to="/mysubscriptions" class="card theben-card">
          <img src="@/assets/abo.svg">
          <span>{{ $t('views.home.manageMySubscriptions') }}</span>
        </router-link>
      </b-col>
      <b-col v-if="gateways.length" cols="12" md="auto">
        <router-link to="/users" class="card theben-card">
          <img src="@/assets/users.png">
          <span>{{ $t('views.home.manageUsers') }}</span>
        </router-link>
      </b-col>
      <b-col v-show="isLuxorLivingAdmin || isLuxorLivingLicensing" cols="12" md="auto">
        <router-link to="/subscriptions" class="card theben-card">
          <img src="@/assets/abo.svg">
          <span>{{ $t('views.home.manageSubscriptions') }}</span>
        </router-link>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import User from "@/models/User"
import Auth from "@/services/ACL";
import router from "@/router";
import EventBus from "@/views/partials/EventBus";
import Gateway from "@/models/Gateway";
import Vue from "vue";
import cfg from "@/services/config";
import ExpirationReminder from "@/views/modals/ExpirationReminder";

var _data = {

  _user: {},
  ready: false,
  connectedUser: null,
  gateways: [],
  showExpirationReminder: true,
  expirationReminder: {},

};

export default {
  name: "home",
  components: {ExpirationReminder},
  data: () => {
    return _data;
  },
  methods: {
    refreshGatewaysList: function () {
      EventBus.$emit('openLoader');
      return new Promise((resolve, reject) => {
        Gateway.getGatewaysUser().then(value => {
          this.gateways = value.data;

          this.expirationReminder.expired = [];
          this.expirationReminder.expireSoon = [];
          this.expirationReminder.cancelled = [];
          this.expirationReminder.free = [];
          this.expirationReminder.listOfAllDevicesToShow = [];


          for (let i = 0; i < this.gateways.length; i++) {
            let gateway = this.gateways[i];
            if (gateway.owner) {
              if (gateway.license) {
                if(gateway.license.expiration){
                  let date = new Date(gateway.license.expiration);
                  let todayDate = new Date();
                  let expirationDateMinusFourWeeks = new Date(date.getTime()-(1000*60*60*24*28));
                  let todayDateMinusFourWeeks = new Date(todayDate.getTime()-(1000*60*60*24*28));
                  // console.log("EXPIRATION \t\t\t\t\t\t " +new Date(date.getTime()));
                  // console.log("EXPIRATION MINUS 4 Weeks \t\t " +  expirationDateMinusFourWeeks);
                  // console.log("TODAY \t\t\t\t\t\t\t " + todayDate);
                  // if(expirationDateMinusFourWeeks > todayDateMinusFourWeeks && expirationDateMinusFourWeeks < todayDate && gateway.kind == "free"){
                  //   // console.log("expire soon");
                  //   this.expirationReminder.expireSoon.push({serial: gateway.serial, expiration: gateway.license.expiration});
                  // }
                  // if(new Date(date.getTime()) < todayDate){
                  //   // console.log("expired");
                  //   this.expirationReminder.expired.push({serial: gateway.serial, expiration: gateway.license.expiration});
                  // }
                  // console.log(gateway.name);

                  // status must be cancelled
                  if(gateway.license.status == "cancelled" ){
                    this.expirationReminder.cancelled.push({serial: gateway.serial, expiration: gateway.license.expiration});
                    this.expirationReminder.listOfAllDevicesToShow.push({name: gateway.name, serial: gateway.serial, expiration: gateway.license.expiration});
                    // console.log("Status cancelled");
                  }

                  // or kind must be free and between expiration date and today date must be less then four weeks
                  if(gateway.license.kind == "free" && (expirationDateMinusFourWeeks < todayDate)){
                    this.expirationReminder.free.push({serial: gateway.serial, expiration: gateway.license.expiration});
                    this.expirationReminder.listOfAllDevicesToShow.push({name: gateway.name, serial: gateway.serial, expiration: gateway.license.expiration});
                    // console.log("Kind free");
                  }
                }
              }
            }
          }

          if(this.expirationReminder.listOfAllDevicesToShow.length > 0 ){
            EventBus.$emit('openExpirationReminderModal', this.expirationReminder)
          }
          EventBus.$emit('closeLoader');

          resolve();
        })
            .catch(() => reject(() => {
              EventBus.$emit('closeLoader');
              EventBus.$emit('openErrorModal')
            }));
      });

    },
  },
  created: async function () {

    await Auth.initialized;
    _data.ready = true;
    if (!Auth.isAuthenticated) {
      router.push({name: "logIn", query: {redirect: '/'}});
      //setTimeout(EventBus.$emit("openLogInModal"), 1000);
      setTimeout(() => {
          let user = new User(Auth.connectedUser);
          if(user.billingAddress) {
              if (user.billingAddress.country === undefined || user.billingAddress.country === null || user.billingAddress.country === "") {
                  EventBus.$emit("openSelectCountryModal",user);
              }
          }
      }, "100");
    }

  },
  computed: {
    user: () => {
      return Auth.connectedUser || {};
    },
    isLuxorLivingAdmin: function () {
      if (this.connectedUser && this.connectedUser.clients) {
        for (let c in this.connectedUser.clients) {
          if (this.connectedUser.clients[c].id === cfg.lxClient.client_id) {
            for (let s in this.connectedUser.clients[c].scope) {
              if (this.connectedUser.clients[c].scope[s] === "admin") {
                return true;
              }
            }
          }
        }
      }
      return false;
    },
    isLuxorLivingLicensing: function () {
      if (this.connectedUser && this.connectedUser.clients) {
        for (let c in this.connectedUser.clients) {
          if (this.connectedUser.clients[c].id === cfg.lxClient.client_id) {
            for (let s in this.connectedUser.clients[c].scope) {
              if (this.connectedUser.clients[c].scope[s] === "licensing") {
                return true;
              }
            }
          }
        }
      }
      return false;
    },
    isLicensingCountry: function () {
        if (this.connectedUser && (this.connectedUser.billingAddress.country == "DE" || this.connectedUser.billingAddress.country == "AT"|| this.connectedUser.billingAddress.country == "LU"|| this.connectedUser.billingAddress.country == "NL")) {
            return true;
        }
        return false;
    }


  },
  mounted: function () {
    EventBus.$emit('closeLoader');
  },
  beforeMount: async function () {
    await Auth.initialized;
    if (!Auth.isAuthenticated) {
      //router.push({name:"logIn", query: {redirect: '/'}});
      setTimeout(EventBus.$emit("openLogInModal"), 500);
    }
    await Auth.isConnected;
    this.connectedUser = Auth.connectedUser;
    await this.refreshGatewaysList();

  },

};
</script>

<style lang="scss" scoped>
a.theben-card:hover {
  text-decoration: none;
}

.theben-card {
  //width: 100px !important;
  //height: 100px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;
}

@media(max-width: 768px) {
  .theben-card {
    background-color: transparent;
  }
}

@media(min-width: 768px) {
  .theben-card {
    width: 150px !important;
    height: 150px !important;
  }
}

.theben-right {

}

img {
  height: 50px;
}
</style>
