import Auth from "@/services/ACL";
import config from "@/services/config";

function Gateway(obj) {
    if (!obj) obj = {};
    this.installationName = obj.name || "";
    this.serialNumber = obj.serial || "";
    this.owner = obj.owner || "";
    this.type = obj.type || "";

    this._id = obj._id || "";

    this.validators = {
        installationName: () => {
            return this.installationName && (this.installationName.match(/([^\"{}$])+/g) || []).pop() == this.installationName;
        },
        serialNumber: () => {
            return this.serialNumber && (this.serialNumber.match(/[^\"{}$]+/g) || []).pop() == this.serialNumber;
        },
        owner: () => {
            return this.owner && (this.owner.match(/[^\"{}$]+/g) || []).pop() == this.owner;
        }
    }
};

Gateway.getGatewaysUser = function (justowned) {
    let filter = "";
    if(justowned){
        filter = "?filter=owned";
    }

    return new Promise((resolve, reject) => {
        Auth.authenticatedAxios.request({
            url: `${config.servers.app.baseUrl}gateways`+filter,
            method: 'GET'
        })
            .then(result => {
                return resolve(result);
            })
            .catch(error => {
                return reject(error)
            })
    });
};

Gateway.prototype.addGateway = function () {
    return Auth.authenticatedAxios.request({
        url: `${config.servers.app.baseUrl}gateways/${this.serialNumber}`,
        method: 'POST'
    });
};

Gateway.prototype.gatewayHandOver = function (dataRecup) {
    return Auth.authenticatedAxios.request({
        url: `${config.servers.app.baseUrl}gateways/${this._id}`,
        method: 'PUT',
        data: {
            owner: {
                email: dataRecup.userMail,
                id: dataRecup.idUser
            }
        }
    });
};

Gateway.prototype.inviteUser = function (dataRecup) {
    return Auth.authenticatedAxios.request({
        url: `${config.servers.app.baseUrl}gateways/${this._id}/users/invite`,
        method: 'POST',
        data: {
            email: dataRecup.userMail
        }
    });
};

Gateway.prototype.confirmInvitation = function (gatewayId, gatewayCode, userId) {
    return Auth.authenticatedAxios.request({
        url: `${config.servers.app.baseUrl}gateways/${gatewayId}/users/${userId}/invite/accept`,
        method: 'PUT',
        data: {
            code: gatewayCode
        }
    });
};

Gateway.prototype.editGateway = function () {
    return Auth.authenticatedAxios.request({
        url: `${config.servers.app.baseUrl}gateways/${this._id}`,
        method: 'PUT',
        data: {
            name: this.installationName
        }
    });
};

Gateway.prototype.giveUpOwnership = function () {
    return Auth.authenticatedAxios.request({
        url: `${config.servers.app.baseUrl}gateways/ownership`,
        method: 'DELETE',
        data: {serial : this.serialNumber}
    });
};

Gateway.prototype.deleteGateway = function () {
    return Auth.authenticatedAxios.request({
        url: `${config.servers.app.baseUrl}gateways/${this._id}`,
        method: 'DELETE'
    });
};

Gateway.prototype.confirmHandover = function (gatewayId, gatewayCode, userId) {
    return Auth.authenticatedAxios.request({
        url: `${config.servers.app.baseUrl}gateways/${gatewayId}/users/${userId}/ownership/accept`,
        method: 'PUT',
        data: {
            code: gatewayCode
        }
    });
};
Gateway.prototype.getAdminPass = function (gatewayId) {
    return Auth.authenticatedAxios.request({
        url: `${config.servers.app.baseUrl}gateways/${gatewayId || this._id}/key`,
        method: 'GET'
    });
};
Gateway.prototype.setAdminPass = function (gatewayPass, gatewayId) {
    return Auth.authenticatedAxios.request({
        url: `${config.servers.app.baseUrl}gateways/${gatewayId || this._id}/key`,
        method: 'PUT',
        data: {
            code: gatewayPass
        }
    });
};
Gateway.prototype.revokeUser = function (gatewayId, userId, userEmail) {
    return Auth.authenticatedAxios.request({
        url: `${config.servers.app.baseUrl}gateways/${gatewayId}/users/${userId}/revoke`,
        method: 'PUT',
        data: {
            email: userEmail
        }
    });
}

Gateway.prototype.getPendingInvitations = function () {
    return new Promise((resolve, reject) => {
        Auth.authenticatedAxios.request({
            url: `${config.servers.app.baseUrl}gateways/invites`,
            method: 'GET'
        })
            .then(result => {
                return resolve(result);
            })
            .catch(error => {
                return reject(error)
            })
    });
};

Gateway.prototype.validateOne = function (prop, name, customValidator) {
    if (this[prop] || (customValidator && customValidator.required)) {
        if (!this[prop]) {
            return "common.validation.required";
        }

        if (!this.validators[prop]()) {
            return "common.validation.illegal";
        }

        if ((customValidator && customValidator.validate && customValidator.validate(this[[prop]]))) {
            return name + customValidator.message;
        }
    }

    return null;
};

Gateway.prototype.validate = function (customValidator) {
    if (!customValidator) customValidator = {};
    let validationsErrors = {};

    let installationNameE = this.validateOne('installationName', 'Installation Name', customValidator.installationName);
    if (installationNameE) validationsErrors.installationName = installationNameE;

    let serialNumberE = this.validateOne('serialNumber', 'Serial Number', customValidator.serialNumber);
    if (serialNumberE) validationsErrors.serialNumber = serialNumberE;

    let ownerE = this.validateOne('owner', 'Owner', customValidator.owner);
    if (ownerE) validationsErrors.owner = ownerE;

    return validationsErrors;
}

export default Gateway;
