import Vue from 'vue'
import App from './App'
import Router from './router'
import store from './store'
import { i18n } from '@/services/i18n';
import Auth from "@/services/ACL";
import router from "@/router";
import User from "@/models/User";
import EventBus from "@/views/partials/EventBus.vue";
import SelectCountryModal from "@/views/modals/SelectCountry.vue";

Vue.config.productionTip = false

if("serviceWorker" in navigator) {
  navigator.serviceWorker.getRegistrations().then( 
    function(registrations) { 
    for(let registration of registrations) { 
      registration.unregister(); 
    } 
  }); ;
}

new Vue({
  components: {
    SelectCountryModal
  },
  router: Router,
  store,
  i18n,
  watch: {
    '$route': function(to, from) {
      if (Auth.isAuthenticated) {
        setTimeout(() => {
          let user = new User(Auth.connectedUser);

          if(user.billingAddress) {
            if (user.billingAddress.country === undefined || user.billingAddress.country === null || user.billingAddress.country === ""|| user.billingAddress.country === "null") {
              EventBus.$emit("openSelectCountryModal",user);

              // EventBus.$emit("openSelectCountryModal",user);
              // this.$refs.selectCountryModal.show();
            }
          }
        }, "100");
      }

    }
  },
  render: h => h(App)
}).$mount('#app')
