import Auth from "@/services/ACL";
import config from "@/services/config";
import cfg from "@/services/config";

function Subscription(obj) {
    if (!obj) obj = {};
    this.email = obj.email || "";
    this.owner = obj.owner || "";
    this.gateway = obj.gateway || "";
    this.status = obj.status || "";
    this.expiration = obj.expiration || "";
    this.kind = obj.kind || "";
    this.creation = obj.creation || "";
    this.shopReference = obj.shopReference || "";


    this._id = obj._id || "";

    this.validators = {
        installationName: () => {
            return this.installationName && (this.installationName.match(/([^\"{}$])+/g) || []).pop() == this.installationName;
        },
        serialNumber: () => {
            return this.serialNumber && (this.serialNumber.match(/[^\"{}$]+/g) || []).pop() == this.serialNumber;
        },
        owner: () => {
            return this.owner && (this.owner.match(/[^\"{}$]+/g) || []).pop() == this.owner;
        }
    }
};

Subscription.getSubscriptionForUser = function () {
    return new Promise((resolve, reject) => {
        Auth.authenticatedAxios.request({
            url: `${config.servers.app.baseUrl}gateways/licenses`,
            method: 'GET'
        })
            .then(result => {
                return resolve(result);
            })
            .catch(error => {
                return reject(error)
            })
    });
};

Subscription.prototype.addGateway = function () {
    return Auth.authenticatedAxios.request({
        url: `${config.servers.app.baseUrl}subscription/${this.serialNumber}`,
        method: 'POST'
    });
};

Subscription.prototype.gatewayHandOver = function (dataRecup) {
    return Auth.authenticatedAxios.request({
        url: `${config.servers.app.baseUrl}subscription/${this._id}`,
        method: 'PUT',
        data: {
            owner: {
                email: dataRecup.userMail,
                id: dataRecup.idUser
            }
        }
    });
};

Subscription.prototype.inviteUser = function (dataRecup) {
    return Auth.authenticatedAxios.request({
        url: `${config.servers.app.baseUrl}subscription/${this._id}/users/invite`,
        method: 'POST',
        data: {
            email: dataRecup.userMail
        }
    });
};

Subscription.prototype.confirmInvitation = function (gatewayId, gatewayCode, userId) {
    return Auth.authenticatedAxios.request({
        url: `${config.servers.app.baseUrl}subscription/${gatewayId}/users/${userId}/invite/accept`,
        method: 'PUT',
        data: {
            code: gatewayCode
        }
    });
};

Subscription.prototype.editSubscription = function () {
    let sendData = {
        kind : this.kind,
        expiration:this.expiration,
        shopReference: this.shopReference,
        status: this.status
    }
    return Auth.authenticatedAxios.request({

        headers : {'client' :  cfg.lxClient.client_id, 'client-secret': cfg.lxClient.client_secret},
        url: `${config.servers.app.baseUrl}gateways/${this.gateway}/license`,
        method: 'PUT',
        data: sendData
    });
};

Subscription.prototype.deleteGateway = function () {
    return Auth.authenticatedAxios.request({
        url: `${config.servers.app.baseUrl}subscription/${this._id}`,
        method: 'DELETE'
    });
};

Subscription.prototype.confirmHandover = function (gatewayId, gatewayCode, userId) {
    return Auth.authenticatedAxios.request({
        url: `${config.servers.app.baseUrl}subscription/${gatewayId}/users/${userId}/ownership/accept`,
        method: 'PUT',
        data: {
            code: gatewayCode
        }
    });
};
Subscription.prototype.getAdminPass = function (gatewayId) {
    return Auth.authenticatedAxios.request({
        url: `${config.servers.app.baseUrl}subscription/${gatewayId || this._id}/key`,
        method: 'GET'
    });
};
Subscription.prototype.setAdminPass = function (gatewayPass, gatewayId) {
    return Auth.authenticatedAxios.request({
        url: `${config.servers.app.baseUrl}subscription/${gatewayId || this._id}/key`,
        method: 'PUT',
        data: {
            code: gatewayPass
        }
    });
};
Subscription.prototype.revokeUser = function (gatewayId, userId, userEmail) {
    return Auth.authenticatedAxios.request({
        url: `${config.servers.app.baseUrl}subscription/${gatewayId}/users/${userId}/revoke`,
        method: 'PUT',
        data: {
            email: userEmail
        }
    });
}

Subscription.prototype.getPendingInvitations = function () {
    return new Promise((resolve, reject) => {
        Auth.authenticatedAxios.request({
            url: `${config.servers.app.baseUrl}subscription/invites`,
            method: 'GET'
        })
            .then(result => {
                return resolve(result);
            })
            .catch(error => {
                return reject(error)
            })
    });
};

Subscription.prototype.validateOne = function (prop, name, customValidator) {
    if (this[prop] || (customValidator && customValidator.required)) {
        if (!this[prop]) {
            return "common.validation.required";
        }

        if (!this.validators[prop]()) {
            return "common.validation.illegal";
        }

        if ((customValidator && customValidator.validate && customValidator.validate(this[[prop]]))) {
            return name + customValidator.message;
        }
    }

    return null;
};

Subscription.prototype.validate = function (customValidator) {
    if (!customValidator) customValidator = {};
    let validationsErrors = {};

    let installationNameE = this.validateOne('installationName', 'Installation Name', customValidator.installationName);
    if (installationNameE) validationsErrors.installationName = installationNameE;

    let serialNumberE = this.validateOne('serialNumber', 'Serial Number', customValidator.serialNumber);
    if (serialNumberE) validationsErrors.serialNumber = serialNumberE;

    let ownerE = this.validateOne('owner', 'Owner', customValidator.owner);
    if (ownerE) validationsErrors.owner = ownerE;

    return validationsErrors;
}

export default Subscription;
