<template>
  <b-modal ref="sendResetPasswordLinkModal" centered hide-footer hide-header-close :title="$t('views.modals.sendResetPasswordLink.title')">
    <b-form class="text-left" v-on:submit.prevent="sendResetPasswordLink()">
      <b-alert :variant="formAlert.variant" :show="formAlert.show">
        <div>{{formAlert.message}}</div>
      </b-alert>
      <p>{{$t('views.modals.sendResetPasswordLink.message')}}</p>
      <b-form-group :state="!shallValidate || !userValidation.email" :invalid-feedback="$t(userValidation.email, {name: $t('common.models.user.email')})">
        <b-form-input type="email" :placeholder="$t('common.models.user.email')" v-model="email"></b-form-input>
      </b-form-group>
      <b-form-group>
        <b-button type="submit" v-bind:disabled="pending" variant="primary" class="w-100">{{$t('common.send')}}</b-button>
      </b-form-group>
    </b-form>
  </b-modal>
</template>

<script>
  import User from "@/models/User";
  import router from "@/router";
  import EventBus from "@/views/partials/EventBus";
  
  export default {
    data: () => {
      return {
        email: null,
        formAlert: {
          show: false,
          variant: null,
          message: null
        },
        shallValidate: false,
        pending: false
      }
    },
    methods: {
      sendResetPasswordLink() {
        this.shallValidate = true
        let u = new User({
          email: this.email,
          password: this.password
        });

        if (Object.keys(this.userValidation).length === 0) {
          this.pending = true;
          u.resetPassword()
            .then(() => {
              this.formAlert = {
                show: true,
                variant: "success",
                message: this.$t('views.modals.sendResetPasswordLink.form.alert.success')
              }
            })
            .catch(error => {
              this.formAlert = {
                show: true,
                variant: "danger",
                message: this.$t('views.modals.sendResetPasswordLink.form.alert.error')
              }
              this.pending = false;

            });
        }
      }
    },
    computed: {
      userValidation () {
        let user = new User({
          email: this.email,
          password: this.password
        });
        let criteria = {
          email: {
            required: true
          }
        };
        
        return user.validate(criteria);
      }
    },
    mounted: function() {
      EventBus.$on('openSendResetPasswordLinkModal', () => {
        this.email = null
        this.formAlert.show = false
        this.$refs.sendResetPasswordLinkModal.show()
        this.pending = false;
      });
    }
  };
</script>
