<template>
  <b-modal ref="logInModal" centered hide-footer hide-header-close :title="$t('views.modals.logIn.title')">
    <b-form class="text-left" v-on:submit.prevent="logIn()">
      <b-alert :variant="formAlert.variant" :show="formAlert.show">
        <div>{{formAlert.message}}</div>
      </b-alert>
      <b-form-group :state="!shallValidate || !userValidation.email" :invalid-feedback="$t(userValidation.email, {name: $t('common.models.user.email')})">
        <b-form-input type="email" :placeholder="$t('common.models.user.email')" v-model="email"></b-form-input>
      </b-form-group>
      <b-form-group :state="!shallValidate || !userValidation.locale" :invalid-feedback="$t(userValidation.locale, {name: $t('common.models.user.password')})">
        <b-form-input type="password" :placeholder="$t('common.models.user.password')" v-model="password"></b-form-input>
      </b-form-group>
      <b-form-group>
        <b-button type="submit" v-bind:disabled="pending" variant="primary" class="w-100">{{$t('views.modals.logIn.form.actions.logIn')}}</b-button>
      </b-form-group>
      <b-form-group>
        <b-button v-on:click="openSendResetPasswordLinkModal()" v-bind:disabled="pending" variant="secondary" class="w-100">{{$t('views.modals.logIn.form.actions.sendResetPasswordLink')}}</b-button>
      </b-form-group>
      <hr>
      <b-form-group>
        <b-button v-on:click="openRegisterModal()" v-bind:disabled="pending"  variant="secondary" class="w-100">{{$t('views.modals.logIn.form.actions.register')}}</b-button>
      </b-form-group>
    </b-form>
  </b-modal>
</template>

<script>
  import User from "@/models/User";
  import EventBus from "@/views/partials/EventBus";
  
  export default {
    data: () => {
      return {
        email: "",
        password: "",
        formAlert: {
          show: false,
          variant: null,
          message: null
        },
        shallValidate: false,
        pending: false
      }
    },
    methods: {
      openSendResetPasswordLinkModal () {
        setTimeout(() => {
          this.$refs.logInModal.hide();
        }, 1);

        EventBus.$emit('openSendResetPasswordLinkModal');

      },
      logIn () {
        this.shallValidate = true
        let u = new User({
          email: this.email,
          password: this.password
        });

        if (Object.keys(this.userValidation).length === 0) {
          this.pending = true;
          EventBus.$emit('openLoader');
          u.logIn()
            .then(() => {
              this.formAlert = {
                show: true,
                variant: "success",
                message: this.$t('views.modals.logIn.form.alert.success')
              }

              setTimeout(() => {
                this.email = null;
                this.password = null;
                this.formAlert.show = false;
                this.$refs.logInModal.hide();
                EventBus.$emit('loggedIn');
                EventBus.$emit('closeLoader');

              }, 3000);
            })
            .catch(error => {
              this.formAlert = {
                show: true,
                variant: "danger"
              }
              EventBus.$emit('closeLoader');
              switch (error.response.status) {
                case 400:
                  this.formAlert.message = this.$t('views.modals.logIn.form.alert.badRequest')
                  break
                default:
                  this.formAlert.message = this.$t('common.requestStatuses.error')
              }
            this.pending = false;
            });
        }
      },
      openRegisterModal: function() {
        this.$refs.logInModal.hide();
        EventBus.$emit('openRegisterModal');
      }
    },
    computed: {
      userValidation () {
        let user = new User({
          email: this.email,
          locale: this.password
        });
        let criteria = {
          email: {
            required: true
          },
          locale: {
            required: true
          }
        };
        
        return user.validate(criteria);
      }
    },
    mounted: function() {
      EventBus.$on('openLogInModal', ()=> {
        this.$refs.logInModal.show();
        this.pending = false;
      });
    }
  };
</script>
