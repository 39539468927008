import axios from "axios";
import cfg from "@/services/config";
import Auth from "@/services/ACL";
import EventBus from "@/views/partials/EventBus";
import 'whatwg-fetch';
import { Buffer } from "buffer";

//user model hold the main actions on user data
function User(obj) {
    if (!obj) obj = {};
    this.lastName = obj.lastName || "";
    this.firstName = obj.firstName || "";
    this.email = obj.email || "";
    this.password = obj.password || "";
    this.locale = obj.locale || "";
    this.category = obj.category || "";
    this.street = obj.street || "";
    this.number = obj.number || "";
    this.zipcode = obj.zipcode || "";
    this.city = obj.city || "";
    this.country = obj.country || "";
    this.passwordConfirmation = obj.passwordConfirmation || "";
    this._id = obj._id || "";
    this.billingAddress = obj.billingAddress || "";
    this.shippingAddress = obj.shippingAddress || "";
    this.ustId = obj.ustId || "";
    this.company = obj.company || "";
    this.department = obj.department || "";

    this.passwordRegExp = (() => {
        var charPattern = ['[0-9]', '[A-Z]', '[a-z]', '[^a-zA-Z0-9\s]'];
        var strA = [];

        for (var i = 0; i < charPattern.length; i++) {
            var s = charPattern.slice();
            s.splice(i, 1);
            for (var j = 0; j < s.length; j++) {
                var s2 = s.slice();
                s2.splice(j, 1);
                strA.push("(.*((" + charPattern[i] + ")+(" + s[j] + ")+).*)(.*((" + s2.join('|') + ")+).*)");
                strA.push("(.*((" + s[j] + ")+(" + charPattern[i] + ")+).*)(.*((" + s2.join('|') + ")+).*)");
                strA.push("(.*((" + s2.join('|') + ")+).*)(.*((" + s[j] + ")+(" + charPattern[i] + ")+).*)");
                strA.push("(.*((" + s2.join('|') + ")+).*)(.*((" + charPattern[i] + ")+(" + s[j] + ")+).*)");

            }
        }

        return new RegExp('(' + strA.join('|') + ')', 'g');
    })();

    this.validators = {
        lastName: () => {
            return this.lastName && (this.lastName.match(/([^\"{}$])+/g) || []).pop() == this.lastName ? null : "common.validation.illegal";
        },
        firstName: () => {
            return this.firstName && (this.firstName.match(/[^\"{}$]+/g) || []).pop() == this.firstName ? null : "common.validation.illegal";
        },
        email: () => {
            return this.email && (this.email.match(/([^\s])+@([-\w])+([.][\w]+)+/g) || []).pop() == this.email ? null : "common.validation.illegal";
        },
        password: () => {
            return this.password && this.password.length >= 8 && this.password.length <= 130 && (this.password.match(this.passwordRegExp) || []).pop() == this.password && (this.password.match(/^(?!.*(.)\1{2}).+$/g) || []).pop() == this.password ? null : "common.validation.illegal";
        },
        passwordConfirmation: () => {
            return this.passwordConfirmation && this.passwordConfirmation === this.password ? null : "common.validation.mismatch";
        },
        locale: () => {
            return null;
        },
        category: () => {
            return null;
        }
    }
}

User.prototype.toJSON = function () {
    return {
        lastName: this.lastName,
        firstName: this.firstName,
        email: this.email
    }
};

User.prototype.logIn = function () {

    const data = {
        username: this.email,
        password: this.password,
        response_type: cfg.lxClient.response_type,
        client_id: cfg.lxClient.client_id,
        scope: cfg.lxClient.scope,
        redirect_uri: cfg.lxClient.redirect_uri,
        state: cfg.lxClient.state
    };


    return fetch(cfg.servers.auth.baseUrl + "authorize", {
        method: 'POST', // or 'PUT'
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    })
        .then(data => {
            let url = new URL(data.url);
            let state = url.searchParams.get("state");
            let code = url.searchParams.get("code");

            if (code === null) {
                let error = {};
                error.response = {};
                if (data.ok === false) {
                    error.response = data;
                    throw error;
                } else {
                    error.response.status = 400;
                    throw error;
                }
            } else {

                const params = new URLSearchParams();
                params.append('grant_type', cfg.lxClient.grant_type);
                params.append('code', code);
                params.append('client_id', cfg.lxClient.client_id);
                params.append('client_secret', cfg.lxClient.client_secret);
                params.append('scope', state);

                let p = axios.post(cfg.servers.auth.baseUrl + "token", params);
                p.then(result => {
                    Auth.authToken = result.data["access_token"];
                    Auth.refreshToken = result.data["refresh_token"];

                    const token = result.data["access_token"];

                    // parse token
                    const base64Url = token.split('.')[1];
                    const decoded = Buffer.from(base64Url, 'base64').toString();
                    let jwt = JSON.parse(decoded);

                    let getUserReq = axios.get(cfg.servers.auth.baseUrl + "user/" + jwt._id, {headers: {Authorization: token}});
                    getUserReq.then(respData => {
                        const userData = respData.data;
                        let user = {};
                        user._id = userData._id;
                        user.firstName = userData.firstName;
                        user.lastName = userData.lastName;
                        user.email = userData.email;
                        user.locale = userData.locale;
                        user.active = userData.active;
                        user.roles = userData.roles;
                        user.category = userData.category;
                        user.billingAddress = userData.billingAddress;
                        user.shippingAddress = userData.shippingAddress;
                        user.clients = userData.clients;
                        user.department = userData.department;
                        user.ustId = userData.ustId;
                        user.company = userData.company;



                        Auth.connectedUser = user;
                        Auth.isAuthenticated = true;
                        Auth.watchConnection.resolve(Auth.connectedUser);
                        localStorage.setItem("auth", JSON.stringify({user: Auth.connectedUser, token: Auth.refreshToken}));
                    });



                });

                p.catch(error => {
                    Auth.authToken = null;
                    Auth.refreshToken = null;
                    Auth.isAuthenticated = false;
                    Auth.connectedUser = null;
                    Auth.authFaild = error.response.data.message;
                    localStorage.setItem("auth", "{}");
                    console.warn(error, error.response);
                });
                return p;
            }

        })
        .catch((error) => {
            throw error;
        });
};


User.prototype.logInClient = function (urlData) {

    let url_string = window.location.href;
    let url = new URL(url_string);
    let scope = url.searchParams.get("scope");
    let client_id = url.searchParams.get("client_id");
    let redirect_uri = url.searchParams.get("redirect_uri");
    let state = url.searchParams.get("state");
    let response_type = "code";

    // let form = document.createElement("form");
    // form.action = cfg.servers.auth.baseUrl + "authorize";
    // form.method = "POST";
    // form.innerHTML = `
    //   <input name="username" type="hidden" value="${this.email}">
    //   <input id="passwordInput" type="hidden" name="password" value="">
    //   <input name="client_id" type="hidden" value="${client_id}">
    //   <input name="scope" type="hidden" value="${scope}">
    //   <input name="response_type" type="hidden" value="${response_type}">
    //   <input id="uriInput" type="hidden" name="redirect_uri" value="${redirect_uri}">
    //   <input name="state" type="hidden" value="${state}">
    //   `;
    //
    // document.body.append(form);
    // document.getElementById('passwordInput').value = this.password;
    // document.getElementById('uriInput').value = redirect_uri;
    //
    // form.submit();


    const requestOptions = {
        method: 'POST',
        redirect: 'follow',
        headers: {"Accept": "application/json , text/plain ,*/*", 'Content-Type': 'application/json'},
        body: JSON.stringify(
            {
                "username": this.email,
                "password": this.password,
                "response_type": response_type,
                "client_id": client_id,
                "scope": scope,
                "redirect_uri": redirect_uri,
                "state": state
            })
    };
    let t = fetch(cfg.servers.auth.baseUrl + "authorize", requestOptions)
        .then((res) => {
            let error = {};
            error.response = {};
            let url = new URL(res.url);
            if (url.searchParams.get("error") != null) {
                Auth.authToken = null;
                Auth.refreshToken = null;
                Auth.isAuthenticated = false;
                Auth.connectedUser = null;
                Auth.authFaild = "wrong credentials";
                localStorage.setItem("auth", "{}");

                if (url.searchParams.get("error") == "wrong credentials") {
                    error.response.status = 400;
                } else {
                    error.response.status = 500;
                }
                return Promise.reject(error);
            } else if (url.searchParams.get("code") != null) {
                // do the manual redirect
                window.location = url.href;
            } else {
                error.response.status = 500;
                return Promise.reject(error);
            }
        }).catch((error) => {
            return Promise.reject(error);
        });
    return t;
};


User.prototype.logOut = function (value) {
    let p = axios.request({
        url: cfg.servers.auth.baseUrl + "revoke",
        headers: {'content-type': 'application/json'},
        method: 'POST',
        data: {
            client_id: cfg.lxClient.client_id,
            refresh_token: Auth.refreshToken
        }
    });


    p.finally(res => {
        localStorage.removeItem('auth');
        Auth.authToken = null;
        Auth.refreshToken = null;
        Auth.isAuthenticated = false;
        Auth.connectedUser = null;
        EventBus.$emit('loggedOut');
    });

    return p;
};

User.prototype.resetPassword = function () {
    let p = axios.request({
        url: cfg.servers.auth.baseUrl + "user/request-password-reset",
        method: 'POST',
        data: {
            "email": this.email
        }
    });

    p.then(() => {
        Auth.authToken = null;
        Auth.refreshToken = null;
        Auth.isAuthenticated = false;
        Auth.connectedUser = null;
        localStorage.setItem("auth", "{}");
    });

    p.catch((error) => {
        Auth.authToken = null;
        Auth.refreshToken = null;
        Auth.isAuthenticated = false;
        Auth.connectedUser = null;
        localStorage.setItem("auth", "{}");
        console.warn(error, error.response);
    });

    return p;
};

User.prototype.save = function (upsert) {
    let options = {};
    //console.log('captcha', this.captcha);
    if (upsert && !this._id) {
        options.url = cfg.servers.auth.baseUrl + "user/";
        options.method = 'POST';
        options.data = {
            lastName: this.lastName,
            firstName: this.firstName,
            email: this.email,
            password: this.password,
            locale: this.locale,
            category: this.category,
            billingAddress: this.billingAddress,
            shippingAddress: this.shippingAddress,
            company: this.company,
            department: this.department,
            ustId: this.ustId,
            robot: this.captcha
        };
    } else {
        options.url = cfg.servers.auth.baseUrl + "user/" + this._id;
        options.method = 'PUT';
        options.data = {
            lastName: this.lastName,
            firstName: this.firstName,
            email: this.email,
            locale: this.locale,
            category: this.category,
            billingAddress: this.billingAddress,
            shippingAddress: this.shippingAddress,
            company: this.company,
            department: this.department,
            ustId: this.ustId,

        };
    }

    let p = Auth.authenticatedAxios.request(options);

    return p;
};

User.prototype.confirmMail = function (id) {
    if (!id) {
        console.warn("User:confirmMail = id is missing");
        return;
    }

    let p = axios.request({
        url: cfg.servers.auth.baseUrl + "user/confirm-user",
        method: 'POST',
        data: {
            code: id
        }
    });

    p.then(result => {
        Auth.authToken = "";
        Auth.refreshToken = null;
        Auth.connectedUser = "";
        Auth.isAuthenticated = false;
        localStorage.setItem("auth", "{}");
    });

    p.catch((error, resp) => {
        Auth.authToken = null;
        Auth.isAuthenticated = false;
        Auth.connectedUser = null;
        localStorage.setItem("auth", "{}");
        console.warn(error, error.response);
    });

    return p;
};

User.prototype.confirmPassword = function (id, password) {
    let p = axios.request({
        url: cfg.servers.auth.baseUrl + "user/confirm-password-reset",
        method: 'POST',
        data: {
            code: id,
            password: password
        }
    });

    p.then(() => {
        Auth.authToken = "";
        Auth.connectedUser = "";
        Auth.isAuthenticated = false;
        Auth.refreshToken = null;
        localStorage.setItem("auth", "{}");
    });

    p.catch((error, resp) => {
        Auth.authToken = null;
        Auth.refreshToken = null;
        Auth.isAuthenticated = false;
        Auth.connectedUser = null;
        localStorage.setItem("auth", "{}");
        console.warn(error, error.response);
    });

    return p;
};

User.prototype.getUserInfo = (id) => {
    return new Promise((resolve, reject) => {
        let options = {};
        options.url = cfg.servers.auth.baseUrl + "user/" + id + "/info";
        options.method = 'GET';
        Auth.authenticatedAxios.request(options
        )
            .then(result => {
                return resolve(result);
            })
            .catch(error => {
                console.log('Erreur')

                return reject(error)
            })
    });
};


User.prototype.getAllUsers  = (ids) => {
    return new Promise((resolve, reject) => {
        let options = {};
        options.url = cfg.servers.auth.baseUrl + "user/list";
        options.method = 'POST';
        options.headers = {'client' :  cfg.lxClient.client_id, 'client-secret': cfg.lxClient.client_secret};
        options.data = {
            ids: ids
        };

        Auth.authenticatedAxios.request(options
        )
            .then(result => {
                return resolve(result);
            })
            .catch(error => {
                console.log('Erreur')

                return reject(error)
            })
    });
};


User.prototype.deleteAccount = function () {
    let options = {};
    options.url = cfg.servers.auth.baseUrl + "user/" + this._id;
    options.method = 'DELETE';

    let p = Auth.authenticatedAxios.request(options);

    return p;
};

User.prototype.validateOne = function (prop, customValidator) {
    if (this[prop] || (customValidator && customValidator.required) && !this[prop]) {
        if (!this[prop]) {
            return "common.validation.required";
        }

        if ((customValidator && customValidator.validate && customValidator.validate(this[[prop]]))) {
            return customValidator.translationKey;
        }

        return this.validators[prop]();
    }

    return null;
};

User.prototype.validate = function (customValidator) {
    if (!customValidator) customValidator = {};
    let validationsErrors = {};

    let lastNameE = this.validateOne('lastName', customValidator.lastName);
    if (lastNameE) validationsErrors.lastName = lastNameE;

    let firstNameE = this.validateOne('firstName', customValidator.firstName);
    if (firstNameE) validationsErrors.firstName = firstNameE;

    let emailE = this.validateOne('email', customValidator.email);
    if (emailE) validationsErrors.email = emailE;

    let passwordE = this.validateOne('password', customValidator.password);
    if (passwordE) validationsErrors.password = passwordE;

    let localeE = this.validateOne('locale', customValidator.locale);
    if (localeE) validationsErrors.locale = localeE;

    let categoryE = this.validateOne('category', customValidator.category);
    if (categoryE) validationsErrors.category = categoryE;

    let passwordConfirmationE = this.validateOne('passwordConfirmation', customValidator.passwordConfirmation);
    if (passwordConfirmationE) validationsErrors.passwordConfirmation = passwordConfirmationE;

    return validationsErrors;
}

User.prototype.checkCode = function (code) {
    return new Promise((resolve, reject) => {
        let options = {};
        options.url = cfg.servers.auth.baseUrl + "user/confirm-code";
        options.method = 'POST';
        options.data = {
            code: code
        };

        Auth.authenticatedAxios.request(options
        )
            .then(result => {
                return resolve(result);
            })
            .catch(error => {
                console.error('Erreur');

                return reject(error)
            })
    });
};

export default User;
